(function() {
  'use strict';

  function FilesSearch(
    $q,
    $filter,
    moment,
    Auth,
    Cache,
    Events,
    Users,
    FILE_TYPES
  ) {
    var PLACEHOLDER = 'Start typing to search...';

    function getExistingEventTypes() {
      var prom;
      var username = Users.remoteUser || Auth.currentUser();
      prom = Events.getUniqueEventTypes(username)
        .then(function(data) {
          var items = _.map(data, function(item) {
            return { id: item.id, key: item.id, title: item.title };
          });
          return items;
        });

      return prom;
    }

    /**
     * This should return all event types available to search.
     * It should be possible to search for:
     *   1. Any that I have on my timeline
     *   2. Published event types so that I can check that I have none
     *
     * It shouldn't list archived ones (unless I have them) so that it is not covered
     * in mess I'm not interested to
     * @return {[eventType]}         [versionId, name]
     */
    function getEventTypes() {
      // All event types which already exist on user's timeline
      var existingEventTypesForUser = getExistingEventTypes();
      return existingEventTypesForUser;
    }

    var getFilesSearch = function(options) {
      // merge all defaultFilters
      var defaultFilter = {};
      options = _.isObject(options) ? options : {};
      defaultFilter = _.assignIn({}, defaultFilter || {}, (options.defaultFilter || {}).filter);

      var search = {
        facetly: {
          facets: [
            {
              id: 'text',
              type: 'text',
              placeholder: 'Start typing to search'
            },
            {
              id: 'fileTypes',
              type: 'select',
              multiselect: true,
              label: 'File Types',
              options: FILE_TYPES.filter(item => item.id !== 'all'),
              facetly: false,
              advanced: true
            },
            {
              id: 'eventType_versionGroupId',
              type: 'select',
              label: 'Event type',
              facetly: false,
              multiselect: true,
              advanced: true,
              options: getEventTypes()
            },
            {
              id: 'createdDate_start',
              type: 'date',
              label: 'Created date from',
              facetly: false,
              advanced: true
            },
            {
              id: 'createdDate_end',
              type: 'date',
              label: 'Created date to',
              facetly: false,
              advanced: true
            }
          ],
          options: {
            defaultFacet: 'name',
            placeholder: PLACEHOLDER,
            listMaxItems: 200
          }
        },
        filters: [
          {
            id: 'visibility',
            matchFunc: function(item, _key, value) {
              var file = item.main || item;
              return value.indexOf(file.doc.visbility) === -1;
            }
          }
        ],
        orders: {
          addedDate: function(item) {
            var file = item.main || item;
            var date = file.doc.createdDate;
            if (date) {
              return -(moment(date).toDate().getTime());
            }

            return Number.MIN_SAFE_INTEGER;
          }
        },
        orderGroups: {
          addedDate: {
            title: 'date added',
            orders: ['addedDate'],
            groupBy: {
              title: 'File created in',
              notitle: 'File without a date',
              getter: function(item) {
                var file = item.main || item;
                var date = file.doc.createdDate;
                if (date) {
                  return $filter('date')(date, 'MMMM y');
                }

                return;
              }
            }
          },
          filename: {
            title: 'file name',
            orders: ['filename']
          }
        },
        defaultFilter: defaultFilter,
        defaultOrder: 'addedDate'
      };

      search.ready = false;
      return $q.when(search);
    };

    var searches = { search: getFilesSearch };

    var getSearch = function(searchType) {
      var func = searches[searchType];
      if (func === undefined) {
        return $q.reject({ status: 500, message: 'An unknown search type: ' + searchType });
      }

      var ext = _.assignIn({
        key: 'search-' + searchType,
        maxAge: 60 * 60 * 1000,
        cached: true
      });
      return Cache.cachedPromise(func, ext);
    };

    var service = { getSearch: getSearch };

    return service;
  }

  FilesSearch.$inject = [
    '$q',
    '$filter',
    'moment',
    'AuthService',
    'CacheService',
    'EventsService',
    'UsersService',
    'FILE_TYPES'
  ];

  angular.module('component.files')
    .service('FilesSearch', FilesSearch);
})();
