(function() {
  'use strict';

  function ImportCSVController(
    $q,
    $rootScope,
    $scope,
    $state,
    capitalizeFilter,
    Form,
    Importer,
    Notify,
    Utils
  ) {
    var ctrl = this;
    ctrl.mapping = {};
    ctrl.entryUniqueness = {};
    ctrl.files = {};
    ctrl.dataFileOptions = {
      accept: ['csvs'],
      multiple: false,
      required: true,
      storeSingle: true
    };

    ctrl.fileSaved = function(files) {
      if (_.isEmpty(files)) {
        return;
      }

      let fileModel = files[0];
      if (fileModel.file === undefined) {
        return;
      }

      ctrl.parseFile(fileModel.file).then(function(columns) {
        ctrl.fileColumns = columns;

        var extra = { eventTypeId: $scope.import.eventTypeId };
        var proms = [
          Importer.getKzFields($scope.import.docType, extra, $scope.import.options.action),
          Importer.getEntryUniquenessKzFields($scope.import.docType, extra)
        ];
        $q.all(proms)
          .then(function(result) {
            ctrl.kzFields = result[0];
            ctrl.entryUniquenessKzFields = result[1];
          })
          .catch(Utils.showError);
      });
    };

    ctrl.parseFile = function(file) {
      var q = $q.defer();
      var reader = new FileReader();

      reader.readAsText(file);
      reader.onload = function() {
        q.resolve(reader.result.split(/\r?\n/)[0].split(','));
      };
      reader.onerror = function() {
        console.log(reader.error);
        q.reject(reader.error);
      };
      return q.promise;
    };

    function getActionForm(docType, userPermissions) {
      var allUsersPerms = [];
      _.forEach(userPermissions, function(perms) {
        _.forEach(perms, function(perm) {
          allUsersPerms.push(perm);
        });
      });
      // var docType = $scope.import.docType;
      var createPerms = ['users.create', 'events.create', 'events.create.own'],
          updatePerms = [
            'users.edit',
            'roles.assign',
            'credential.manage',
            'events.edit',
            'events.edit.own',
            'events.editCompleted',
            'events.editCompleted.own',
            'goals.manage'
          ];
      var options = [
        {
          _id: 'createOnlyIfNotExists',
          key: 'createOnlyIfNotExists',
          name: 'Search for existing data, create new records which do not exist, do not update ' +
                'existing data',
          permsToHave: createPerms
        },
        {
          _id: 'createOrUpdate',
          key: 'createOrUpdate',
          name: 'Search for existing data, create new records which do not exist, update ' +
                'existing data',
          permsToHave: createPerms.concat(updatePerms)
        },
        {
          _id: 'updateOnly',
          key: 'updateOnly',
          name: 'Search for existing data, do not create new records, update existing data',
          permsToHave: updatePerms
        },
        {
          _id: 'forceCreate',
          key: 'forceCreate',
          name: 'Create all records in the file, do not check for existing data',
          permsToHave: createPerms,
          hideForDocTypes: ['user']
        }
      ];
      options = _.filter(options, function(opt) {
        return !_.isEmpty(_.intersection(allUsersPerms, opt.permsToHave)) &&
                (_.isUndefined(opt.hideForDocTypes) ||
                _.indexOf(opt.hideForDocTypes, docType) === -1);
      });

      return new Form([
        {
          id: 'action',
          type: 'discrete',
          label: 'How should the import behave?',
          required: true,
          defaultValue: 'createOnlyIfNotExists',
          options: options
        }
      ]);
    }

    function getBasicForm(userPermissions, availableEventTypes) {
      function onChangeBasicAttrs() {
        delete $scope.import.file;
        delete $scope.import.entry_uniqueness;
        ctrl.fileColumns = undefined;
        ctrl.kzFields = undefined;
        $rootScope.$broadcast('ResetFileInput');
      }

      function docTypeOptions(userPermissions) {
        var docTypeMap = {
          'importer.users': 'user',
          'importer.events': 'event'
        };
        return _.map(_.keys(userPermissions), function(mainPerm) {
          var docType = docTypeMap[mainPerm];
          return { _id: docType, key: docType, name: capitalizeFilter(docType) };
        });
      }

      var defaultValue = 'event';
      if (_.indexOf(_.keys(userPermissions), 'importer.events') === -1) {
        defaultValue = 'user';
      }

      var fields = [
        {
          id: 'name',
          type: 'text',
          label: 'Import task title',
          required: true,
          helpText: 'Provide a label for this import so you can find it later in the import list.'
        },
        {
          id: 'docType',
          type: 'discrete',
          label: 'What type of data would you like to import?',
          required: true,
          options: docTypeOptions(userPermissions),
          defaultValue: defaultValue,
          onChange: function() {
            delete $scope.import.eventTypeId;
            onChangeBasicAttrs();
          },
          helpText: 'Select what type of import you want to make. If available, sub options will ' +
            'be displayed.'
        },
        {
          id: 'eventTypeId',
          type: 'discrete',
          label: 'Event Type',
          required: true,
          hideExpression: function(_$viewValue, _$modelValue, $scope) {
            return _.isUndefined($scope.model) || $scope.model.docType !== 'event';
          },
          options: _.map(availableEventTypes, function(eventType) {
            var name = eventType.name + ' (v' + eventType.version + ') ';
            return { _id: eventType.id, key: eventType.id, name: name };
          }),
          onChange: function() {
            onChangeBasicAttrs();
          }
        }
      ];

      return new Form(fields);
    }

    function getEmailsForm() {
      return new Form([
        {
          id: 'sendEmails',
          type: 'boolean',
          label: 'Would you like to send emails?',
          defaultValue: false,
          required: true
        }
      ]);
    }

    function getlinkExistingEventsToTargetsForm() {
      return new Form([
        {
          id: 'linkExistingEventsToTargets',
          type: 'boolean',
          label: 'Link existing events to targets?',
          required: true,
          defaultValue: false
        }
      ]);
    }

    function getAttachedFileForm() {
      return new Form([
        {
          id: 'attachedFiles',
          type: 'file',
          label: 'Upload your zip file containing the files to attach',
          required: false,
          fileOptions: {
            accept: ['zip'],
            storeSingle: true
          }
        }
      ]);
    }

    function getApplyLastGoalSetVersionForm() {
      return new Form([
        {
          id: 'applyLastGoalSetVersion',
          type: 'boolean',
          label: 'Apply last goal set version?',
          required: true,
          defaultValue: false
        }
      ]);
    }

    function initWatches() {
      $scope.$watchCollection('import.docType', function(newDocType) {
        if (_.isUndefined(newDocType)) {
          return;
        }

        ctrl.actionForm = getActionForm(newDocType, ctrl.userPermissions);
      });

      $scope.$watchCollection('import.options.action', function(newAction) {
        if ($scope.import.docType === undefined) {
          return;
        }

        ctrl.mapping = ctrl.model.mapping;
        var extra = { eventTypeId: $scope.import.eventTypeId };
        Importer.getKzFields($scope.import.docType, extra, newAction)
          .then(function(kzFields) {
            ctrl.kzFields = kzFields;
          });
      });
    }

    this.$onInit = function() {
      $scope.import = ctrl.model || {};
      ctrl.mapping = ctrl.model.mapping;
      ctrl.entryUniqueness = ctrl.model.entry_uniqueness;
      // delete $scope.import.mapping;

      Importer.getAllUserPermissions()
        .then(function(userPermissions) {
          ctrl.userPermissions = userPermissions;
          return Importer.getAvailableEventTypes(userPermissions['importer.events']);
        })
        .then(function(availableEventTypes) {
          ctrl.availableEventTypes = availableEventTypes;

          ctrl.eventTypesContainingGoals = [];
          ctrl.eventTypesContainingFileFields = [];
          _.forEach(availableEventTypes, function(eventType) {
            if (eventType.isFirstSectionContainsAGoalField) {
              ctrl.eventTypesContainingGoals.push(eventType.id);
            }
            if (eventType.isFirstSectionContainsAFileField) {
              ctrl.eventTypesContainingFileFields.push(eventType.id);
            }
          });

          ctrl.docTypeForm = getBasicForm(ctrl.userPermissions, availableEventTypes);
          ctrl.emailsForm = getEmailsForm();
          ctrl.linkExistingEventsToTargetsForm = getlinkExistingEventsToTargetsForm();
          ctrl.applyLastGoalSetVersionForm = getApplyLastGoalSetVersionForm();
          ctrl.attachedFilesForm = getAttachedFileForm();
          ctrl.fileColumns = $scope.import.fileColumns;
          if (ctrl.model.options?.action) {
            var extra = { eventTypeId: ctrl.model.eventTypeId };
            return $q.all([
              Importer.getKzFields(
                ctrl.model.docType, extra, ctrl.model.options.action
              ).then(kzFields => {
                ctrl.kzFields = kzFields;
              }),
              Importer.getEntryUniquenessKzFields(ctrl.model.docType, extra)
                .then(entryUniquenessKzFields => {
                  ctrl.entryUniquenessKzFields = entryUniquenessKzFields;
                })
            ]);
          }
        })
        .then(function() {
          initWatches();
        })
        .then(function() {
          ctrl.loaded = true;
        });
    };

    function isValid() {
      var notValidFields = [];
      if (_.isEmpty($scope.import.name)) {
        notValidFields.push('Import task title is missing');
      }

      // docType
      var docTypeValid = !_.isEmpty($scope.import.docType);
      if ($scope.import.docType === 'event') {
        docTypeValid = !_.isEmpty($scope.import.eventTypeId);

        // check if the event type is still published.
        var eventTypeIds = _.map(ctrl.availableEventTypes, function(eventType) {
          return eventType.id;
        });
        if (_.indexOf(eventTypeIds, $scope.import.eventTypeId) === -1) {
          var msg = 'The selected event type is now archived, please choose a published one.';
          notValidFields.push(msg);
        }
      }

      if (!docTypeValid) {
        notValidFields.push('The type of data');
      }

      if (_.isEmpty($scope.import.file)) {
        notValidFields.push('Please select a file');
      }

      // mapping
      // required mapping
      var mappingValid = true;
      if ($scope.import.options.action !== 'updateOnly') {
        if (!_.isEmpty(ctrl.mapping)) {
          var requiredFields = _.chain(ctrl.kzFields)
            .filter(function(field) {
              return field.required === true;
            })
            .map(function(field) {
              return field._id;
            })
            .value();

          var mappedFields = _.map(ctrl.mapping, function(_map) {
            return _map.fieldId;
          });

          _.forEach(requiredFields, function(requiredField) {
            if (_.indexOf(mappedFields, requiredField) === -1) {
              mappingValid = false;
              return;
            }
          });
        } else {
          mappingValid = false;
        }
      }

      if (!mappingValid) {
        notValidFields.push('Some required fields were not matched');
      }

      if (_.isEmpty(ctrl.entryUniqueness)) {
        notValidFields.push('You have chosen to search for existing data but no columns have ' +
          'been selected');
      } else if ($scope.import.docType === 'event') {
        // check that at least one user field has been mapped
        var userFieldsMapped = _.filter(ctrl.kzFields, function(field) {
          return field.user_field === true && _.indexOf(ctrl.entryUniqueness, field._id) > -1;
        });

        if (userFieldsMapped.length === 0) {
          notValidFields.push('At least one user field should be mapped');
        }
      }

      return notValidFields;
    }

    ctrl.previewEmail = function(docType, action) {
      var emails = {
        user: {
          create: {
            title: 'Users will receive this email when the profile is created only if you ' +
              'defined a local username and password',
            text: 'You have received this email because you have been added as a new ' +
              'risr/advance user.\n\n' +
              'Here are your credentials:\n' +
              ' - Username: {{ username }}\n' +
              ' - Password: set it by clicking here\n'
          },
          update: {
            title: 'Users will not receive any emails when the profile is updated'
          }
        },
        event: {
          create: {
            title: 'Users will receive this email if the event is completed.',
            text: 'An event has just been completed on your timeline.\n' +
              'Click here to view the full event {{ NAME_OF_THE_EVENT }}'
          },
          update: {
            title: 'Users will not receive any emails when the title is updated'
          }
        }
      };

      if (_.isUndefined(docType) || _.isUndefined(action)) {
        return;
      }

      var content = emails[docType][action];

      Utils.swal({
        title: content.title,
        text: content.text || '',
        type: 'info',
        showCancelButton: false,
        allowEscapeKey: true,
        confirmButtonText: 'OK',
        closeOnConfirm: true
      });
    };

    function startImport(importDoc, dryrun) {
      var name = importDoc.name,
          docType = importDoc.docType,
          bulkFileName = importDoc.file.filename,
          bulkFileColumns = ctrl.fileColumns,
          dataFile = importDoc.file,
          attachedFiles = importDoc.attachedFiles,
          extra = { eventTypeId: importDoc.eventTypeId },
          mapping = ctrl.mapping,
          entryUniqueness = ctrl.entryUniqueness,
          options = importDoc.options;

      if (_.isUndefined(dryrun)) {
        dryrun = true;
      }

      Importer.importFile(
        name,
        docType,
        extra,
        bulkFileName,
        bulkFileColumns,
        dataFile,
        attachedFiles,
        mapping,
        entryUniqueness,
        options,
        ctrl.id,
        dryrun
      )
        .then(function(response) {
          Notify.success('Import created');
          $state.go('epf.importer.view', { id: response.task_id });
        })
        .catch(Utils.showError);
    }

    ctrl.startImport = function(importDoc, dryrun) {
      var errors = isValid();
      if (!_.isEmpty(errors)) {
        var text = '';
        _.forEach(errors, function(error) {
          text += error + ' \n';
        });
        Utils.swal({
          title: 'They are some errors in the form: \n' + text,
          type: 'warning',
          showCancelButton: false,
          confirmButtonText: 'OK'
        });
        return;
      }

      if (dryrun) {
        startImport(importDoc, dryrun);
      } else {
        Utils.swal({
          title: 'Are you sure you would like to run this import for real?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'OK'
        },
        function(isConfirm) {
          if (isConfirm) {
            startImport(importDoc, dryrun);
          }
        });
      }
    };

    ctrl.downloadCSVTemplate = function(importDoc) {
      var docType = importDoc.docType,
          extra = { eventTypeId: importDoc.eventTypeId };
      return Importer.downloadCSVTemplate(docType, extra);
    };
  }

  ImportCSVController.$inject = [
    '$q',
    '$rootScope',
    '$scope',
    '$state',
    'capitalizeFilter',
    'FormsService',
    'ImporterService',
    'NotifyService',
    'UtilsService'
  ];

  angular.module('component.importer')
    .component('importCsv', {
      templateUrl: 'app/components/importer/components/importCSV.html',
      controller: ImportCSVController,
      bindings: {
        id: '<',
        model: '<'
      }
    });

  angular.module('component.importer')
    .directive('ngUploadChange', function() {
      return {
        scope: {
          ngUploadChange: '&'
        },
        link: function($scope, $element) {
          $element.on('change', function(event) {
            $scope.$apply(function() {
              $scope.ngUploadChange({ $event: event });
            });
          });
          $scope.$on('$destroy', function() {
            $element.off();
          });

          $scope.$on('ResetFileInput', function() {
            $element.val(null);
          });
        }
      };
    });
})();
